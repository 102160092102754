<template>
	<div class="page-header is-sticky">
		<div class="page-component">
			<div class="display-flex is-between">
				<div class="fl">
					<button type="button" class="reserv-page-header__button" @click.prevent="goBack()">
						<icon-left :width="12" :height="20" stroke="#424242" :stroke-width="2" />
					</button>
					<h2 class="page-header-title is-subtitle">{{title}}</h2>
				</div>
				<div class="fr">
					<button v-if="showMyRsvtBtn && my" type="button" class="button-default is-small is-primary is-rounded" @click="goMyRsvt()">내 예약</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconLeft from '@/components/common/IconLeft.vue';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';
import { mapGetters } from 'vuex';
export default {
	name: 'ReservPageHeader',
	components: { IconLeft },
	props: {
		title: {
			type: String,
			default: '',
		},
		prevPath: {
			type: String,
			default: '',
		},
		my: {
			type: Boolean,
			default: true,
		},
		showMyRsvtBtn: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
    	...mapGetters('auth', ['session', 'isAuth']),
	},
	mounted() {},
	data: () => ({}),
	methods: {
		goBack() {
			if(!this.my){	// 내 예약 에서만 히스토리백. 그 외에서는 SpaceHome 으로 이동
				let go = -1;
				if(this.prevPath ==  '/login' && typeof this.session.userId != 'undefined') go = -3;
				this.$router.go(go);
			} else {
				this.$router.push({ name: 'SpaceHome' });
			}			
		},
		goMyRsvt(){
			
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: false,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path + (this.$route.query.tab == undefined ? '' : '?tab=' + this.$route.query.tab));
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			//this.$router.push('/mypage/reserve');
			this.$router.push({ name: 'SpaceMyRsvt' });
		}, 
	},
};
</script>
