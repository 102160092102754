<template>
	<main class="page-container" id="reservation-room">
		<reserv-page-header :title="roomInfo.spaceNm" />

		<div class="page-component">
			<div class="reserv-detail-header__gallery">
				<ul>
					<li v-for="(glry, idx) in glrtDtlList" :key="idx">
						<a @click.prevent="onClickShowImg(glrtDtlList, idx)">
							<span v-if="totalCount > 5 && idx == 4" class="thumb-more">
								<i class="icon-thumb-more"></i>
								<em> + {{ totalCount - 5 }}</em>
							</span>
							<span class="thumb">
								<img v-if="glry.imgFileUrl" :src="glry.imgFileUrl" :alt="glry.imgFileNm" @error="handleImgError" />
								<img v-else src="@/assets/img/common/noimage.png" alt="" @error="handleImgError" />
							</span>
						</a>
					</li>
				</ul>
			</div>
			<space-gallery-swiper ref="gallerySwiper"></space-gallery-swiper>

			<!-- <div class="reserv-room-swiper">
				<h2 class="reserv-room-swiper__title">{{ roomName }}</h2>
				<swiper class="swiper" ref="swipeRoom" :options="swiperOptionRoom">
					<swiper-slide v-for="(img, idx) in glrtDtlList" :key="idx">
						<span class="thumb-default">
							<img :src="img.imgFileUrl" alt="" />
						</span>
					</swiper-slide>
				</swiper>
				<div class="swiper-buttons-container swiper-buttons-container__sround">
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
			</div> -->

			<section class="reserv-room-content content">
				<h3 class="reserv-room-content__title">
					<!-- {{ room.roomOnelineIntro }}<br /> -->
					{{ roomName }}
				</h3>
				<div class="content reserv-room-content__desc" v-html="roomInfo.introCn"></div>
			</section>

			<section class="reserv-room-section">
				<div class="reserv-room-section__header">
					<h3 class="reserv-room-section__title">예약일 선택</h3>
				</div>
				<!--    달력      -->
				<div class="calendar-default">
					<div class="month-selector">
						<button class="btn-selector btn-selector-prev" @click="changeMonth(false)">
							<i class="icon-arrow">이전 달</i>
						</button>
						<!--              <h5 class="calendar-title">{{ year }}.{{ month < 10 ? `0${month}` : month }}</h5>-->
						<h5 class="calendar-title">{{ year }}년 {{ month }}월</h5>
						<button class="btn-selector btn-selector-next" @click="changeMonth(true)">
							<i class="icon-arrow">다음 달</i>
						</button>
					</div>
					<calendar ref="revCalendar" :select-year="year" :select-month="month" :select-day="day" :weekend-yn="roomInfo.wekendOperYn" :holy-day-yn="roomInfo.holdyOperYn" :select-func="selectCalendarDay" />
				</div>

				<div v-if="!isReservedNot" class="time-selector">
					<h4 class="time-selector-title">{{ year }}-{{ month < 10 ? `0${month}` : month }}-{{ day < 10 ? `0${day}` : day }}({{ getDayOfWeek(year, month, day) }}) 시간 선택</h4>
					<div class="reserv-room-scrollwrap">
						<ol class="time-selector-list">
							<li v-for="(schedule, idx) in scheduleItems" class="time-selector-item" :key="`schedule-${idx}`">
								<div class="time-selector-check">
									<input v-model="checked" type="checkbox" :id="`time-selector-${idx}`" class="time-selector-input" :value="schedule" :disabled="schedule.status !== 'E'" @change="isCheckedChange($event)" />
									<label :for="`time-selector-${idx}`" class="time-selector-label">
										<span class="time">{{ schedule.strtHhmm.substring(0, 2) }}:{{ schedule.strtHhmm.substring(2, 4) }} </span>
									</label>
								</div>
							</li>
						</ol>
					</div>
					<div class="time-selector-legend">
						<span class="time-status is-disabled">마감</span>
						<span class="time-status">예약가능</span>
					</div>
				</div>
			</section>

			<section v-if="roomInfo.esntlCnfmCaseCn" class="reserv-room-section content">
				<div class="reserv-room-section__header">
					<h3 class="reserv-room-section__title">필수 확인 사항</h3>
				</div>
				<!--              <p class="livestudio-warning-description">{{room.roomEsntlNtc}}</p>-->
				<pre class="reserv-room-content__desc" :style="{ whiteSpace: 'pre-wrap' }">{{ roomInfo.esntlCnfmCaseCn }}</pre>
			</section>

			<div v-if="!isReservedNot" class="buttons flex align-items-center justify-content-center gap-10">
				<button type="button" class="button-default is-large is-gray is-rounded" @click="goList">목록</button>
				<!-- <router-link class="button-default is-large is-secondary is-rounded" @click="rsvtTimeCalc">다음단계</router-link> -->
				<button class="button-default is-large is-secondary is-rounded" @click="clickNext">다음단계</button>
				<!-- <login-check-button class="button-default is-large is-secondary is-rounded" :disabled="isLoading" :click-func="clickSave" :is-auth="isAuth">
					<span class="text">다음단계</span>
				</login-check-button> -->
			</div>
			<div v-else class="buttons flex justify-content-center">
				<p class="reserv-warning-title"><i class="icon-warning"></i> 오늘 이후 예약 가능한 날짜를 선택해 주세요</p>
			</div>
		</div>
	</main>
</template>

<script>
import Calendar from '@/components/space/Calendar';
import { ACT_GET_STUDIO_RESERVATION_SCHEDULE_LIST, ACT_GET_STUDIO_ROOM, ACT_GET_SPACE_ROOM_INFO, ACT_GET_SPACE_RSVT_SCHD_LIST } from '@/store/_act_consts';
import { collectionCheck, getItem, getItems, lengthCheck, setParams } from '@/assets/js/utils';
import { MUT_SHOW_ALERT, MUT_SHOW_RESERVATION_MODAL, MUT_SET_RETURN_ROUTE, MUT_SET_SPACE_RSVT_TIMES } from '@/store/_mut_consts';
import LoginCheckButton from '@/components/common/LoginCheckButton';
import { mapGetters } from 'vuex';
import ReservPageHeader from '@/components/space/PageHeader';
import { getTokenByKey } from '@/assets/js/jwt.service';
import SpaceGallerySwiper from '@/components/space/GallerySwiper.vue'; // 탭 - 갤러리 스와이퍼

export default {
	name: 'SpaceRsvt',
	components: {
		ReservPageHeader,
		Calendar,
		LoginCheckButton,
		SpaceGallerySwiper,
	},
	watch: {
		// checked: {
		// 	handler(newVal, oldVal) {
		// 		if(newVal.length > 8){
		// 			this.errorAlert('최대 4시간까지만 예약이 가능합니다.');
		// 			newVal.pop();
		// 			//this.checked.pop();
		// 			const timeChecked = document.querySelector('.time-selector-input');
		// 			timeChecked.dispatchEvent(new Event('input'));
		// 		}
		// 	},
		// 	immediate: true,
		// },
	},
	data: () => ({
		swiperOptionRoom: {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 0,
			navigation: {
				nextEl: '.reserv-room-swiper .swiper-button-next',
				prevEl: '.reserv-room-swiper .swiper-button-prev',
			},
		},

		isReservedNot: false,

		//////////////////////////////////////
		spaceId: 0,
		roomId: 0,
		roomInfo: {
			roomId: 0,
			spaceId: 0, // 공간_ID
			roomNm: '', // 룸명
			maxRsvtUsrcnt: 0, // 최대 예약 인원
			operMemo: '', // 운영 시간
			spaceImpsbTimeUseYn: '', // 공간이용불가시간사용여부(공간과동일)
			itemDcdList: '', // 품목 DCD 리스트
			itemEtcArcCn: '', // 품목기타항목 내용
			intro: '', // 소개글
			introCn: '', // 소개글
			esntlCnfmCaseCn: '', // 필수확인사항
			useYn: '',
			impsbTimeDomains: [],
			glryDtlDomains: [],
			spaceNm: '',
			rprsImgFileUrl: '',
			operStrtHhmm: '',
			operEndHhmm: '',
			holdyOperYn: '',
			wekendOperYn: '',
		},
		itemDcdList: [],
		glrtDtlList: [],
		impsbTimes: [
			{
				strtHhmm: '0000',
				endHhmm: '0000',
			},
		],
		totalCount: 0,

		isLoading: false,
		currentIdx: 0,
		checked: [],

		year: 0,
		month: 0,
		day: 0,

		todayYmd: '',
		todayY: 0,
		todayM: 0,
		todayD: 0,

		reservedTms: [],
	}),
	computed: {
		...mapGetters('auth', ['isAuth']),
		...mapGetters('space', ['rsvtTimes']),
		slideStyle() {
			return { transform: `translate3d(${this.currentIdx * -660}px, 0px, 0px)`, 'transition-duration': `300ms` };
			// transform: translate3d(-660px, 0px, 0px); transition-duration: 0ms;
			// '1320'
		},
		roomName() {
			const result = [];
			if (this.roomInfo.spaceNm) result.push(this.roomInfo.spaceNm);
			if (this.roomInfo.roomNm) result.push(this.roomInfo.roomNm);
			return result.join(' — ');
		},
		scheduleItems() {
			const result = [];
			if (this.roomInfo.operStrtHhmm && this.roomInfo.operEndHhmm) {
				const beginHour = parseInt(this.roomInfo.operStrtHhmm.substring(0, 2));
				const beginMin = this.roomInfo.operStrtHhmm.substring(2, 4);
				const endHour = parseInt(this.roomInfo.operEndHhmm.substring(0, 2));
				const endMin = this.roomInfo.operEndHhmm.substring(2, 4);
				let cnt = 0;
				for (let i = beginHour; i < endHour; i++) {
					if (i === beginHour && beginMin === '30') {
						// 운영 시작 시간이 30분 일때
						result.push({
							seq: cnt,
							strtHhmm: `${String(i).padStart(2, '0')}30`,
							endHhmm: `${String(i + 1).padStart(2, '0')}00`,
							status: this.checkSchedule(`${String(i).padStart(2, '0')}30`),
						});
						cnt++;
					} else if (i === endHour - 1 && endMin === '30') {
						// 운영 종료 시간이 30분일때
						result.push({
							seq: cnt,
							strtHhmm: `${String(i).padStart(2, '0')}00`,
							endHhmm: `${String(i).padStart(2, '0')}30`,
							status: this.checkSchedule(`${String(i).padStart(2, '0')}00`),
						});
						cnt++;
						result.push({
							seq: cnt,
							strtHhmm: `${String(i).padStart(2, '0')}30`,
							endHhmm: `${String(i + 1).padStart(2, '0')}00`,
							status: this.checkSchedule(`${String(i).padStart(2, '0')}30`),
						});
						cnt++;
						//종료 시간 30분 추가
						result.push({
							seq: cnt,
							strtHhmm: `${String(endHour).padStart(2, '0')}00`,
							endHhmm: `${String(endHour).padStart(2, '0')}30`,
							status: this.checkSchedule(`${String(endHour).padStart(2, '0')}`),
						});
						cnt++;
					} else {
						result.push({
							seq: cnt,
							strtHhmm: `${String(i).padStart(2, '0')}00`,
							endHhmm: `${String(i).padStart(2, '0')}30`,
							status: this.checkSchedule(`${String(i).padStart(2, '0')}00`),
						});
						cnt++;
						result.push({
							seq: cnt,
							strtHhmm: `${String(i).padStart(2, '0')}30`,
							endHhmm: `${String(i + 1).padStart(2, '0')}00`,
							status: this.checkSchedule(`${String(i).padStart(2, '0')}30`),
						});
						cnt++;
					}
				}
			}
			return result;
		},
		currentYmd() {
			return `${this.year}${this.month < 10 ? `0${this.month}` : this.month}${this.day < 10 ? `0${this.day}` : this.day}`;
		},
	},
	created() {
		this.isLoginCheck();

		const todayDate = new Date();
		this.todayY = todayDate.getFullYear();
		this.todayM = todayDate.getMonth() + 1;
		this.todayD = todayDate.getDate();
		// this.todayYMd = `${this.todayY}${this.todayM < 10 ? `0${this.todayM}`: this.todayM}${this.todayD < 10 ? `0${this.todayD}`: this.todayD}`;
		this.setCurrentDate();
		if (this.$route.params.spaceId) this.spaceId = this.$route.params.spaceId;
		if (this.$route.params.roomId) this.roomId = this.$route.params.roomId;

		if (this.roomId > 0) {
			this.getRoomInfo();
		}
		//주말 예약 오류 수정 22.09.24 pjs
		const vm = this;
		setTimeout(() => {
			const nextMonthDay = new Date(vm.year, vm.month - 1, vm.day);
			const isdis = vm.$refs.revCalendar.checkDisabled(nextMonthDay.getDay(), { year: vm.year, month: vm.month, day: vm.day });
			//console.log(isdis);
			if (!isdis) {
				vm.clearChecked();
				vm.getReservationScheduleList();
			} else {
				vm.isReservedNot = true;
			}
		}, 500);
	},
	methods: {
		isLoginCheck() {
			if (!getTokenByKey('auth_token')) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}
		},
		getSpaceInfo() {
			this.$store.dispatch(`space/${ACT_GET_SPACE_INFO}`, this.info.spaceId).then((res) => {
				if (lengthCheck(res)) {
					this.item = getItem(res);
					//소개TAB
					this.info.intro.contents = this.item.introCn;
					this.info.intro.snsList = this.item.snsList;

					this.info.bizplMeta = {
						addr: this.item.addr,
						addrDtl: this.item.addrDtl,
						telNo: this.item.telNo,
						guideCn: this.item.guideCn,
					};
				}
			});
		},
		getRoomInfo() {
			const param = { roomId: this.roomId };
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_ROOM_INFO}`, param)
				.then((res) => {
					if (lengthCheck(res)) {
						this.roomInfo = getItem(res);
						this.itemDcdList = this.roomInfo.itemDcdList.split(',');
						this.impsbTimes = this.roomInfo.impsbTimeDomains;

						if (this.roomInfo.glryDtlDomains) {
							// 대표이미지 맨 앞으로 이동
							const rprsImgIdx = this.roomInfo.glryDtlDomains.findIndex((item) => item.rprsImgYn === 'Y');
							if (rprsImgIdx !== -1) {
								const yItem = this.roomInfo.glryDtlDomains.splice(rprsImgIdx, 1)[0];
								this.roomInfo.glryDtlDomains.unshift(yItem);
							}

							this.glrtDtlList = this.roomInfo.glryDtlDomains;
						}

						// this.addFormatImpsbTimes();
					}
				})
				.catch((e) => {
					console.error(e);
					this.roomId = 0;
				});
		},
		// addFormatImpsbTimes() {
		// 	this.impsbTimes = this.impsbTimes.map((imp) => {
		// 		return {
		// 			...imp,
		// 			// strtHhmm: this.formatTime(imp.strtHhmm),
		// 			// endHhmm: this.formatTime(imp.endHhmm),
		//       strtHhmm: imp.strtHhmm,
		// 			endHhmm: imp.endHhmm,
		// 		};
		// 	});
		// },
		checkSchedule(time) {
			const reservFiltered = this.reservedTms.filter((x) => x.rsvtBgngHhmm <= time && x.rsvtEndHhmm > time);
			const impsbFiltered = this.impsbTimes.filter((x) => x.strtHhmm <= time && x.endHhmm > time);
			if (reservFiltered.length > 0) {
				if (reservFiltered[0].mineYn === 'Y') {
					return 'M'; // my schedule
				} else {
					return 'R'; // reserved
				}
			} else if (impsbFiltered.length > 0) {
				return 'I'; // IMPST Time
			} else {
				return 'E';
			}
		},
		getReservationScheduleList() {
			if (this.currentYmd <= this.todayYmd) {
				this.isReservedNot = true;
			} else {
				this.isReservedNot = false;
				this.$store
					.dispatch(`space/${ACT_GET_SPACE_RSVT_SCHD_LIST}`, {
						roomId: this.roomId,
						params: {
							rsvtYmd: this.currentYmd,
						},
					})
					.then((res) => {
						if (lengthCheck(res)) {
							this.reservedTms = getItems(res);
						} else {
							this.reservedTms = [];
						}
					})
					.catch((e) => {
						console.error(e);
						this.reservedTms = [];
					});
			}
		},
		setCurrentDate() {
			let nowDate = new Date();
			nowDate.setDate(nowDate.getDate() + 1);
			this.year = nowDate.getFullYear();
			this.month = nowDate.getMonth() + 1;
			this.day = nowDate.getDate();
		},
		clearChecked() {
			this.checked = [];
		},
		isCheckedChange(e) {
			if (this.checked.length > 8) {
				this.errorAlert('최대 4시간까지만 예약이 가능합니다.');
				this.checked.pop();
				e.target.checked = false;
			}
		},
		selectCalendarDay(day, isDisabled) {
			// if(this.month === day.month){
			//console.log(isDisabled);
			if (!isDisabled) {
				this.day = day.day;
				this.clearChecked();
				this.getReservationScheduleList();
			}
		},
		changeMonth(isNext) {
			this.day = 1;
			if (isNext) {
				if (this.month === 12) {
					this.year++;
					this.month = 1;
				} else {
					this.month++;
				}
			} else {
				if (this.month === 1) {
					this.year--;
					this.month = 12;
				} else {
					this.month--;
				}
			}
			const vm = this;
			setTimeout(() => {
				const nextMonthDay = new Date(vm.year, vm.month - 1, vm.day);
				const isdis = vm.$refs.revCalendar.checkDisabled(nextMonthDay.getDay(), { year: vm.year, month: vm.month, day: vm.day });
				//console.log(isdis);
				if (!isdis) {
					vm.clearChecked();
					vm.getReservationScheduleList();
				} else {
					vm.isReservedNot = true;
				}
			}, 500);
		},
		// 날짜의 요일 구하기
		getDayOfWeek(year, month, day) {
			const date = new Date(year, month - 1, day);
			const days = ['일', '월', '화', '수', '목', '금', '토'];
			const dayOfWeek = days[date.getDay()];
			return dayOfWeek;
		},
		rsvtTimeCalc() {
			// console.log('next');
			const rsvtTimes = this.checked.sort(function (comp1, comp2) {
				var comp1UC = comp1.seq;
				var comp2UC = comp2.seq;
				if (comp1UC < comp2UC) {
					return -1;
				} else if (comp1UC > comp2UC) {
					return 1;
				}
				return 0;
			});
			// console.log(rsvtTimes);
			const rsvtStateParam = {
				rsvtDt: `${this.year}-${String(this.month).padStart(2, '0')}-${this.day}`,
				rsvtDayWeek: this.getDayOfWeek(this.year, this.month, this.day),
				rsvtTimeList: [],
			};

			for (let i = 0; i < rsvtTimes.length; i++) {
				if (i === 0) {
					// 첫 예약시간 추가
					rsvtStateParam.rsvtTimeList.push({ rsvtBgngHhmm: rsvtTimes[i].strtHhmm, rsvtEndHhmm: rsvtTimes[i].endHhmm, min: 30 });
				} else {
					if (rsvtStateParam.rsvtTimeList[rsvtStateParam.rsvtTimeList.length - 1].rsvtEndHhmm === rsvtTimes[i].strtHhmm) {
						// 배열의 전 예약 종료시간이 다음 시작시간과 같을때 (이어지는 예약 일때)
						rsvtStateParam.rsvtTimeList[rsvtStateParam.rsvtTimeList.length - 1].rsvtEndHhmm = rsvtTimes[i].endHhmm;
						rsvtStateParam.rsvtTimeList[rsvtStateParam.rsvtTimeList.length - 1].min += 30;
					} else {
						// 예약시간이 이어지지 않을때 신규 추가
						rsvtStateParam.rsvtTimeList.push({ rsvtBgngHhmm: rsvtTimes[i].strtHhmm, rsvtEndHhmm: rsvtTimes[i].endHhmm, min: 30 });
					}
				}
			}

			//console.log(rsvtStateParam);
			this.$store.commit(`space/${MUT_SET_SPACE_RSVT_TIMES}`, rsvtStateParam);
		},
		clickNext() {
			if (this.checked.length === 0) {
				this.errorAlert('예약일시를 선택해주세요.');
				return;
			} else {
				const allMinutes = this.checked.length * 30; // 총 분수
				const allHours = allMinutes / 60; //총 시간
				if (allHours > 4) {
					// 4시간보다 많을때
					this.errorAlert('최대 4시간까지만 예약이 가능합니다.');
					return;
				} else {
					this.rsvtTimeCalc();
					this.$router.push({ name: 'SpaceRsvtInput' });
				}
			}
		},
		errorAlert(msg) {
			this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: msg });
		},
		goList() {
			this.$router.push(`/space/${this.spaceId}?tab=reserv`);
		},
		handleImgError(e) {
			e.target.src = errorImg;
		},
		// 동적 컴포넌트 관련 - 끝
		// event ===============================================================
		onClickShowImg(glrtDtlList = [], idx = 0) {
			if (this.totalCount > 4) {
				//5개 이상일 경우 더보기
				if (idx == 4 && this.totalCount > 5) this.$router.push({ query: { tab: 'gallery' } }).catch(() => {});
				else if (this.glrtDtlList.length == 5) this.$refs.gallerySwiper.onClickShowImg(this.glrtDtlList, idx);
				else this.$refs.gallerySwiper.onClickShowImg(this.glrtDtlList.slice(0, 4), idx);
			} else this.$refs.gallerySwiper.onClickShowImg(this.glrtDtlList, idx);
		},
		onClickHide() {
			this.$refs.gallerySwiper.onClickHide();
		},
	},
};
</script>
